$.fn.date_format = function (format) {
    return function (data, type, row) {
        res = moment(data).format(format);

        return(res == 'Invalid date' ? '' : res);
    };
};

$.fn.render_tooltip = function (length) {
    return function ( data, type, row ) {
        summary = data;
        if (typeof length !== "undefined" && length != 0 && length < data.length) {
            summary = data.substr(0, length) + " …"
        }

        return '<span data-toggle="tooltip" data-placement="top"' +
            'title="' + data + '">' +
            summary
            '</span>';
    };
};

require('datatables.net-bs4');
require('datatables.net-buttons-bs4');

